/* eslint-disable react/jsx-curly-brace-presence */
/* eslint-disable import/extensions */
/* eslint-disable import/no-unresolved */
import React from 'react'
import { graphql } from 'gatsby'
import Img from 'gatsby-image'

// Components
import SEO from 'components/SEO'
import Layout from 'components/shared/Layout'
import GravityForm from 'components/GravityForm'
import ParseContent from 'components/shared/ParseContent'

// Third Party
import styled from 'styled-components'

const ContentContainer = styled.div`
  width: 380px;

  @media screen and (max-width: 991px) {
    width: 70%;
    display: flex;
    justify-content: center;
  }

  @media screen and (max-width: 675px) {
    width: 90%;
    display: flex;
    justify-content: center;
  }

  @media screen and (max-width: 400px) {
    width: 100%;
    display: flex;
    justify-content: center;
  }
`

const BlueBox = styled.div`
  width: 380px;
  height: 295px;
  padding-left: 60px;
  padding-right: 60px;

  @media screen and (max-width: 400px) {
    padding-left: 40px;
    padding-right: 40px;
  }

  a {
    &:hover {
      color: ${props => props.theme.color.contrast};
    }
  }
`

const ContactTitle = styled.h2`
  font-size: ${props => props.theme.font.size.xxxxl};

  @media screen and (max-width: 991px) {
    font-size: ${props => props.theme.font.size.xxxl};
    text-align: center;
  }
`

const WagonsTitle = styled.h2`
  font-size: ${props => props.theme.font.size.xxxxl};

  @media screen and (max-width: 991px) {
    font-size: ${props => props.theme.font.size.xxl};
    text-align: center;
  }
`

const StyledImg = styled(Img)`
  @media screen and (max-width: 576px) {
    height: 300px;
  }
`

const Contact = styled.div`
  margin-top: 275px;

  @media screen and (max-width: 991px) {
    margin-top: 50px;
  }
`

const PageTemplate = ({
  data: {
    page: { 
      path,
      acf: {
        content_group: contentGroup,
        contact_group: contactGroup,
        heinen_en_zoon_group: heinenEnZoonGroup
      },
      yoastMeta
    }
  }
}) => {
  return (
    <Layout>
      <SEO yoast={{ meta: yoastMeta }} pathname={path} article />
      <Contact className="container py-5">
        <ContactTitle className="font-family-secondary">{contentGroup.title}</ContactTitle>
        <div className="row">
          <div className="col-lg-6 d-flex d-lg-block flex-wrap justify-content-center">
            <ContentContainer className="pb-4 pb-lg-0">
              <ParseContent content={contentGroup.text} />
            </ContentContainer>
            <BlueBox className="d-flex align-items-center color-background-main mb-5 mb-lg-0 py-3 py-lg-4">
              <div>
                <h2 className="mb-4 text-uppercase font-weight-xl font-size-xm color-text-contrast">{contactGroup.title}</h2>
                <p className="mb-0 font-size-m color-text-light">{contactGroup.company_name}</p>
                <p className="mb-4 font-size-m color-text-light">{contactGroup.city}</p>
                <a className="w-100 color-text-light" href={`tel:${contactGroup.phonenumber}`}>
                  <strong>{`T `}</strong>
                  {contactGroup.phonenumber}
                </a>
                <br />
                <a className="color-text-light" href={`mailto:${contactGroup.mail}`}>
                  <strong>{`E `}</strong>
                  {contactGroup.mail}
                </a>
              </div>
            </BlueBox>
          </div>
          <div className="col-lg-6">
            <GravityForm id={1} />
          </div>
        </div>
        <div className="row py-5" />
        <WagonsTitle className="font-size-xxxl font-family-secondary">{heinenEnZoonGroup.title}</WagonsTitle>
        <div className="row pb-5">
          <div className="col-lg-6">
            <div className="row">
              <div className="col-sm-6 pb-3 pb-lg-0">
                <StyledImg fluid={heinenEnZoonGroup.image_group[0].image.localFile.childImageSharp.fluid} alt="" />
              </div>
              <div className="col-sm-6 pb-3 pb-lg-0">
                <StyledImg fluid={heinenEnZoonGroup.image_group[1].image.localFile.childImageSharp.fluid} alt="" />
              </div>
            </div>
          </div>
          <div className="col-lg-6">
            <div className="row">
              <div className="col-sm-6 pb-3 pb-lg-0">
                <StyledImg fluid={heinenEnZoonGroup.image_group[2].image.localFile.childImageSharp.fluid} alt="" />
              </div>
              <div className="col-sm-6 pb-3 pb-lg-0">
                <StyledImg fluid={heinenEnZoonGroup.image_group[3].image.localFile.childImageSharp.fluid} alt="" />
              </div>
            </div>
          </div>
        </div>
      </Contact>
    </Layout>
  )
}

export default PageTemplate

export const pageQuery = graphql`
  query($wordpress_id: Int) {
    page: wordpressPage(wordpress_id: { eq: $wordpress_id }) {
      title
      path
      acf {
        content_group {
          title
          text
        }
        contact_group {
          title
          company_name
          city
          phonenumber
          mail
        }
        heinen_en_zoon_group {
          title
          image_group {
            image {
              localFile {
                childImageSharp {
                  fluid(quality:100, maxWidth: 600) {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }
            }
          }
        }
      }
      yoastMeta: yoast_meta {
        name
        content
        property
      }
    }
  }
`
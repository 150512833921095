/* eslint-disable import/extensions */
/* eslint-disable import/no-unresolved */
import React from 'react'
import styled from 'styled-components'
import ButtonShell from './ButtonShell'

const StyledButton = styled.div`
  background-color: ${props => props.theme.color.contrast};
  display: inline-block;
  transition: 0.25s;

  &:hover {
    transform: scale(1.05);
  }
  
  & > a, & > button {
    color: ${props => props.theme.color.text.secondary};
    padding: 10px 15px;
    width: 100%;
    height: 100%;
    display: block;
  }
`

const ButtonDefault = ({ isAnchor, isCustom, to, children, className }) => (
  <StyledButton className={className}>
    {isCustom ? children : (
      <ButtonShell to={to} isAnchor={isAnchor}>
        {children}
      </ButtonShell>
    )}
  </StyledButton>
)

export default ButtonDefault